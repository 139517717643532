// LockScreenContext.tsx
import React, { createContext, useContext, useState } from "react";

const LockScreenContext = createContext({
	isLocked: false,
	lock: () => {},
	unlock: () => {},
});

export const useLockScreen = () => useContext(LockScreenContext);

export const LockScreenProvider: React.FC<{ children:React.ReactNode }> = ({children}) => {
	const [isLocked, setIsLocked] = useState(false);

	const lock = () => setIsLocked(true);
	const unlock = () => setIsLocked(false);

	return (
		<LockScreenContext.Provider value={{ isLocked, lock, unlock }}>
			{children}
		</LockScreenContext.Provider>
	);
};
