import { StringParam } from "firebase-functions/lib/params/types";

export interface ISlide {
	id: number;
	title: string;
	index: number;
}

interface ISlideRange {
	slides: ISlide[];
	shapes: IShape[];
}

interface IShape {
	id: string;
}

export interface IPowerPointSlide {
	id: number;
	title: string;
	index: number;
	slideTitle: string;
	slideId: string;
	slideIndex: number;
	shapeCount: number;
	shapeTypes: string[]; // Array of strings to hold shape types
}

interface IPowerPointSlideRange {
	slides: IPowerPointSlide[];
}
/* Office types have not been updated with isDarkTheme - Create change request for office.js team */
export interface OfficeThemeLatest extends Office.OfficeTheme {
	isDarkTheme: boolean;
}

export function addFileAttachmentFromBase64(
	fileContents: string,
	attachmentName: string
): boolean {
	// Get a handle on the current item (message or appointment).
	var item = Office.context.mailbox.item;

	// Call the addFileAttachmentFromBase64Async method to add the attachment.
	Office.context.mailbox.item.addFileAttachmentFromBase64Async(
		fileContents,
		attachmentName,
		function (result) {
			if (result.status === Office.AsyncResultStatus.Succeeded) {
				console.log("Attachment added successfully");
				return true;
			} else {
				console.log("Error adding attachment: " + result.error.message);
				return false;
			}
		}
	);
	return false;
}

export async function loadFileName(): Promise<string | null> {
	return new Promise((resolve) => {
		Office.context.document.getFilePropertiesAsync(null, (res) => {
			if (res && res.value && res.value.url) {
				let name = res.value.url.substr(
					res.value.url.lastIndexOf("\\") + 1
				);
				resolve(name);
			}
			resolve(null);
		});
	});
}

export const saveDocumentSettings = async (data: { [key: string]: any }) => {
	for (const key in data) {
		Office.context.document.settings.set(key, data[key]);
	}
	await Office.context.document.settings.saveAsync();
};

export const getDocumentSettings = async (data: Array<string>) => {
	const ret: { [key: string]: string } = {};

	data.forEach(async (key) => {
		console.log("key", key);
		const value = await Office.context.document.settings.get(key);
		ret[key] = value;
	});

	return ret;
};

export async function tagForAutoOpen(): Promise<void> {
	return new Promise(async (resolve) => {
		Office.context.document.settings.set(
			"Office.AutoShowTaskpaneWithDocument",
			true
		);
		await Office.context.document.settings.saveAsync();
		console.log("*************Document tagged for auto open*************");
		resolve();
	});
}

export function insertSlideExternal(dataBase64: string) {
	Office.context.document.getSelectedDataAsync(
		Office.CoercionType.SlideRange,
		async (asyncResult: Office.AsyncResult<ISlideRange>) => {
			var targetId: undefined | string = undefined;

			if (asyncResult.status == Office.AsyncResultStatus.Failed) {
				console.error(
					"Unable to get selected data: " + asyncResult.error.message
				);
			} else {
				targetId = "" + asyncResult.value.slides[0].id;
			}
			await PowerPoint.run(async function (context) {
				// get currently selected slide
				const newSlide = context.presentation.insertSlidesFromBase64(
					dataBase64,
					{ targetSlideId: targetId } //, formatting: "UseDestinationTheme" }
				);
				await context.sync();
			});
		}
	);
}

export async function getCurrentSlide(): Promise<ISlide> {
	return new Promise<ISlide>((resolve, reject) => {
		Office.context.document.getSelectedDataAsync(
			Office.CoercionType.SlideRange,
			async (asyncResult: Office.AsyncResult<ISlideRange>) => {
				var targetId: undefined | string = undefined;

				if (asyncResult.status == Office.AsyncResultStatus.Failed) {
					reject(
						"Unable to get selected data: " +
							asyncResult.error.message
					);
				} else {
					resolve(asyncResult.value.slides[0]);
				}
			}
		);
	});
}

export async function getSelectedSlides(): Promise<ISlide[]> {
	return new Promise<ISlide[]>((resolve, reject) => {
		Office.context.document.getSelectedDataAsync(
			Office.CoercionType.SlideRange,
			async (asyncResult: Office.AsyncResult<ISlideRange>) => {
				var targetId: undefined | string = undefined;

				if (asyncResult.status == Office.AsyncResultStatus.Failed) {
					reject(
						"Unable to get selected data: " +
							asyncResult.error.message
					);
				} else {
					resolve(asyncResult.value.slides);
				}
			}
		);
	});
}

export async function getAllShapes(): Promise<PowerPoint.Shape[]> {
	return new Promise<PowerPoint.Shape[]>(async (resolve, reject) => {
		await PowerPoint.run(async (context) => {
			try {
				const slide = context.presentation.slides.getItemAt(0);
				const shapes = slide.shapes;

				// Load all the shapes in the collection without loading their properties.
				shapes.load("items/Name");
				await context.sync();

				const s = shapes.items.map((shape) => shape);
				//shapes.items.forEach(function (shape) {
				//	console.log(shape);
				//});
				resolve(s);
				await context.sync();
			} catch (error) {
				reject(error);
			}
		});
	});
}

export async function getIdBootstrapToken(): Promise<string> {
	try {
		const accessToken = await Office.auth.getAccessToken({
			allowSignInPrompt: true,
			allowConsentPrompt: true,
			//forMSGraphAccess: false, // TODO: This is a outlook only thing and should be enabled for PowerPoint
		});
		console.log("Access token", accessToken);
		return accessToken;
	} catch (error) {
		console.log("Error obtaining token", error);
		return null;
	}
}

export async function getShapeById(shapeId: string): Promise<PowerPoint.Shape> {
	return new Promise(async (resolve, reject) => {
		await PowerPoint.run(async (context) => {
			// Attempt to get the shape by its ID
			context.presentation.load("slides");
			await context.sync();
			const slide = context.presentation.slides.getItemAt(0);
			const shape = slide.shapes.getItem(shapeId);
			shape.load("id"); // Load properties of the shape, in this case, just the ID

			// Synchronize and resolve the promise if successful
			slide.context
				.sync()
				.then(() => {
					resolve(shape);
				})
				.catch((error) => {
					reject(`Failed to get shape: ${error}`);
				});
		});
	});
}

export async function setSelectedShapes(shapesIds: string[]) {
	// Selects the first two shapes on slide 1.
	await PowerPoint.run(async (context) => {
		context.presentation.load("slides");
		await context.sync();
		const slide1 = context.presentation.slides.getItemAt(0);
		//slide1.load("shapes");
		await context.sync();
		//const shapes = slide1.shapes;
		//const shape1 = shapes.getItemAt(0);
		//const shape2 = shapes.getItemAt(1);
		//shape1.load("id");
		//shape2.load("id");
		//await context.sync();
		slide1.setSelectedShapes(shapesIds);
		await context.sync();
	});
}

// https://raw.githubusercontent.com/OfficeDev/office-js-snippets/prod/samples/powerpoint/shapes/get-set-shapes.yaml

export async function goToSlideById(id: number): Promise<void> {
	return new Promise((resolve, reject) => {
		Office.context.document.goToByIdAsync(
			id.toString(),
			Office.GoToType.Slide,
			function (result: Office.AsyncResult<any>) {
				if (result.status === Office.AsyncResultStatus.Succeeded) {
					console.log("Navigated to slide with id ", id);
					resolve();
				} else {
					console.error(
						"Error navigating to slide: " + result.error.message
					);
					reject();
				}
			}
		);
	});
}

export async function deleteSlide(idx: number): Promise<void> {
	await PowerPoint.run(async function (context) {
		// The slide index is zero-based.
		const slide = context.presentation.slides.getItemAt(idx);
		slide.delete();

		await context.sync();
	});
}

export async function getSelectedShapes(): Promise<PowerPoint.Shape[]> {
	const shapeIds: PowerPoint.Shape[] = [];
	await PowerPoint.run(async (context) => {
		const shapes = context.presentation.getSelectedShapes();

		const shapeCount = shapes.getCount();
		await context.sync();
		shapes.load("items");
		await context.sync();
		shapes.items.map((shape, index) => {
			shapeIds.push(shape);
		});
	});
	return shapeIds;
}

// Get all of the content from a PowerPoint document in 100-KB chunks of text.
export const getFileAsBase64 = (
	fileType?: Office.FileType
): Promise<string> => {
	return new Promise<string>((resolve, reject) => {
		let aggregatedData: Array<number> = [];

		function sendFile() {
			Office.context.document.getFileAsync(
				fileType || Office.FileType.Compressed,
				{ sliceSize: 50000 },
				function (result) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						const myFile = result.value;
						const state = {
							file: myFile,
							counter: 0,
							sliceCount: myFile.sliceCount,
						};

						console.log(
							"Getting file of " + myFile.size + " bytes"
						);
						getSlice(state);
					} else {
						console.log(result.status);
						reject("Failed to get file");
					}
				}
			);
		}

		function getSlice(state: {
			file?: any;
			counter: any;
			sliceCount: any;
			data?: any;
		}) {
			state.file.getSliceAsync(
				state.counter,
				function (result: {
					status: Office.AsyncResultStatus;
					value: any;
				}) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						console.log(
							"Sending piece " +
								(state.counter + 1) +
								" of " +
								state.sliceCount
						);
						// Aggregate the data from each slice
						try {
							//console.log("RESULT DATA:", result.value.data);
							aggregatedData.push(...result.value.data);
							//aggregatedData.push(...[1,2,3]);
							//console.log("Aggregated", aggregatedData);
						} catch (error) {
							console.log("Error", error);
						}
						state.counter++;

						if (state.counter < state.sliceCount) {
							getSlice(state);
						} else {
							closeFile(state);
						}
					} else {
						console.log(result.status);
						reject("Failed to get slice");
					}
				}
			);
		}

		function closeFile(state: {
			counter: any;
			sliceCount: any;
			file?: any;
		}) {
			state.file.closeAsync(function (result: {
				status: Office.AsyncResultStatus;
			}) {
				if (result.status === Office.AsyncResultStatus.Succeeded) {
					console.log("File closed.");
					console.log("And we have some data", aggregatedData);

					//const uint8Array = new Uint8Array(aggregatedData);
					console.log(aggregatedData.length);

					const uint8Array = new Uint8Array(aggregatedData);

					console.log("uint array", uint8Array);

					// Convert Uint8Array to base64
					//const uint8Array = new Uint8Array([65, 66]); // Example data
					try {
						function uint8ArrayToBase64(ui8array: Uint8Array) {
							let binaryString = "";
							ui8array.forEach((byte) => {
								binaryString += String.fromCharCode(byte);
							});
							return window.btoa(binaryString);
						}

						const base64String = uint8ArrayToBase64(uint8Array);
						console.log(
							"base64 conversion ok",
							base64String.length
						);
						resolve(base64String);
					} catch (error) {
						console.log("Error", error);
						reject("Failed to return base64 string");
					}

					resolve("hi");
				} else {
					console.log("File couldn't be closed.");
					reject("Failed to close file");
				}
			});
		}

		sendFile();
	});
}; // getFileAsBase64

// Slide

export const getFileContents = (
	fileType?: Office.FileType
): Promise<Uint8Array> => {
	return new Promise<Uint8Array>((resolve, reject) => {
		let aggregatedData: Array<number> = [];

		function sendFile() {
			Office.context.document.getFileAsync(
				fileType || Office.FileType.Compressed,
				{ sliceSize: 50000 },
				function (result) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						const myFile = result.value;
						const state = {
							file: myFile,
							counter: 0,
							sliceCount: myFile.sliceCount,
						};

						console.log(
							"Getting file of " + myFile.size + " bytes"
						);
						getSlice(state);
					} else {
						console.log(result.status);
						reject("Failed to get file");
					}
				}
			);
		}

		function getSlice(state: {
			file?: any;
			counter: any;
			sliceCount: any;
			data?: any;
		}) {
			state.file.getSliceAsync(
				state.counter,
				function (result: {
					status: Office.AsyncResultStatus;
					value: any;
				}) {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						console.log(
							"Sending piece " +
								(state.counter + 1) +
								" of " +
								state.sliceCount
						);
						// Aggregate the data from each slice
						try {
							//console.log("RESULT DATA:", result.value.data);
							//aggregatedData.push.apply(aggregatedData, result.value.data);
							aggregatedData.push(...result.value.data);
							//aggregatedData.push(...[1,2,3]);
							//console.log("Aggregated", aggregatedData);
						} catch (error) {
							console.log("Error", error);
						}
						state.counter++;

						if (state.counter < state.sliceCount) {
							getSlice(state);
						} else {
							closeFile(state);
						}
					} else {
						console.log(result.status);
						reject("Failed to get slice");
					}
				}
			);
		}

		function closeFile(state: {
			counter: any;
			sliceCount: any;
			file?: any;
		}) {
			state.file.closeAsync(function (result: {
				status: Office.AsyncResultStatus;
			}) {
				if (result.status === Office.AsyncResultStatus.Succeeded) {
					console.log("File closed.");
					console.log("And we have some data", aggregatedData);

					//const uint8Array = new Uint8Array(aggregatedData);
					console.log(aggregatedData.length);

					const uint8Array = new Uint8Array(aggregatedData);

					resolve(uint8Array);
				} else {
					console.log("File couldn't be closed.");
					reject("Failed to close file");
				}
			});
		}

		sendFile();
	});
}; // getFileAsBase64

// Slides i presentation and titles
// getSlides.ts

export const getSlides = async (): Promise<IPowerPointSlide[]> => {
	try {
		const slidesData: IPowerPointSlide[] = await PowerPoint.run(
			async (context) => {
				const presentation = context.presentation;
				const slides = presentation.slides;
				slides.load("items/shapes");
				await context.sync();
				//console.log("SlidesNEWALL", slides);

				const tempSlidesData = slides.items.map((slide, arrayIndex) => {
					let potentialTitle = "Unknown Title";
					const shapeCount = slide.shapes.items.length;
					const shapeTypes: string[] = [];

					if (shapeCount > 0) {
						slide.shapes.items.forEach((shape) => {
							// Collect the type of each shape
							shapeTypes.push(shape.type);

							// Check if the shape has a textRange property for the potential title
							if ("textRange" in shape) {
								potentialTitle = (shape as any).textRange.text;
							}
						});
					}

					return {
						id: parseInt(slide.id),
						title: potentialTitle,
						index: arrayIndex + 1,
						slideTitle: potentialTitle,
						slideId: slide.id,
						slideIndex: arrayIndex + 1,
						shapeCount,
						shapeTypes, // Add the shape types to the object
					};
				});

				return tempSlidesData;
			}
		);
		return slidesData;
	} catch (error) {
		console.error(error);
		return []; // Return an empty array in case of error
	}
};

export async function getSlidesWithTitles(): Promise<
	{ id: number; title: string }[]
> {
	return new Promise<{ id: number; title: string }[]>(
		async (resolve, reject) => {
			await PowerPoint.run(async (context) => {
				try {
					const slidesWithTitles: any[] = [];
					const slideCount = context.presentation.slides.getCount();
					await context.sync();

					let titleFound:boolean = false;
					for (let i = 0; i < slideCount.value; i++) {
						// Get the slide at the current index
						titleFound = false;
						const slide = context.presentation.slides.getItemAt(i);
						await context.sync();

						const shapes = slide.shapes;
						// Load all the shapes in the collection without loading their properties.
						shapes.load("Name,Type");

						//const shape = shapes.getItem("Title 1");  // Assume "ShapeName" is the name of your shape
						await context.sync();
						//let title = null;
	
						shapes.items.forEach(async (shape) => {
							if (["Title 1", "Tittel 1"].includes(shape.name)) {
								shape.textFrame.textRange.load("text");

								await context.sync();

								slidesWithTitles.push({
									id: Number(slide.id.split("#")[0]),
									title: shape.textFrame.textRange.text,
								});
								titleFound = true;
							}
						});
						if (titleFound === false) {
							slidesWithTitles.push({
								id: Number(slide.id.split("#")[0]),
								title: "Unknown Title",
							});
						} 
					}

					resolve(slidesWithTitles);
					await context.sync();
				} catch (error) {
					reject(error);
				}
			});
		}
	);
}
