import React from "react";
import { Box, Container, CircularProgress, useTheme } from "@mui/material";

// Inspired by the former Facebook spinners.
const FacebookCircularProgress: React.FC = () => {
	return (
		<Box
			sx={{
				display: "flex",
				gap: 3,
				alignItems: "center",
				flexWrap: "wrap",
			}}
		>
			<CircularProgress
				sx={{
					"--CircularProgress-size": "60px",
				}}
			/>
		</Box>
	);
};

export const LoadingPage: React.FC = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundColor: theme.palette.background.default,
				height: "100vh", // Full viewport height
				width: "100vw", // Full viewport width
				position: "fixed", // Fixed position
				top: 0, // Start from the top
				left: 0, // Start from the left
				display: "flex",
				alignItems: "center", // Center vertically
				justifyContent: "center", // Center horizontally
				zIndex: 1500, // Ensure it's above other content
			}}
		>
			<Container
				maxWidth="lg"
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center", // Center horizontally the items of the container
				}}
			>
				<FacebookCircularProgress />
			</Container>
		</Box>
	);
};
