// TitleContext.tsx

import React, {
	createContext,
	useState,
	useContext,
	Dispatch,
	SetStateAction,
	ReactElement,
} from "react";

interface TitleContextType {
	title: string;
	setTitle: Dispatch<SetStateAction<string>>;
}

// Create a context with a default value
export const TitleContext = createContext<TitleContextType | undefined>(
	undefined
);

// Create a provider component
export const TitleProvider: React.FC<{children:ReactElement}> = ({ children }) => {
	const [title, setTitle] = useState<string>("");

	return (
		<TitleContext.Provider value={{ title, setTitle }}>
			{children}
		</TitleContext.Provider>
	);
};

// Hook to use the context
export const useTitleContext = () => {
	const context = useContext(TitleContext);
	if (context === undefined) {
		throw new Error("useTitleContext must be used within a TitleProvider");
	}
	return context;
};
