import React, { Suspense, ComponentType, ReactElement, lazy } from "react";
import { RouteObject, Navigate } from "react-router-dom";
//import { LoadingPage } from "@/components/layout/loading-page";

type LoadableComponentProps = {
	[propName: string]: unknown;
};

// Use a higher-order function to return the component wrapped in Suspense
const Loadable = <P extends LoadableComponentProps>(
	Component: ComponentType<P>
) => {
	// Return a new function that takes props and returns a ReactElement
	return function LoadableComponent(props: P): ReactElement {
		return (
			<Suspense fallback>
				{/*<Suspense fallback={<LoadingPage />}>*/}
				<Component {...props} />
			</Suspense>
		);
	};
};

type DynamicImport = () => Promise<{
	default: ComponentType<LoadableComponentProps>;
}>;

const lazyLoad = (importFunction: DynamicImport) => {
	const LazyComponent = lazy(importFunction);
	return Loadable(LazyComponent);
};

const Layout = lazyLoad(() =>
	import("@/components/layout/layout").then((module) => ({
		default: module.default,
	}))
);

// Lazy loaded components with dynamic import
const NotFound = lazyLoad(() =>
	import("./pages/not-found").then((module) => ({ default: module.NotFound }))
);

const Wax = lazyLoad(() =>
	import("./containers/wax").then((module) => ({ default: module.Wax }))
);

const Admin = lazyLoad(() =>
	import("./containers/admin").then((module) => ({ default: module.default }))
);

const Pax = lazyLoad(() =>
	import("./containers/pax").then((module) => ({ default: module.Pax }))
);

const TemplateLibrary = lazyLoad(() =>
	import("./containers/TemplateLibrary").then((module) => ({
		default: module.TemplateLibrary,
	}))
);

const WaxCompliance = lazyLoad(() =>
	import("./containers/waxCompliance").then((module) => ({
		default: module.WaxCompliance,
	}))
);

const AdminConsent = lazyLoad(() =>
	import("./containers/AdminConsent").then((module) => ({
		default: module.AdminConsent,
	}))
);

const PptAddin = lazyLoad(() =>
	import("./components/pptaddin/pptaddin").then((module) => ({
		default: module.PptAddin,
	}))
);

const OutlookAddin = lazyLoad(() =>
	import("./components/outlookaddin/outlookaddin").then((module) => ({
		default: module.OutlookAddin,
	}))
);

const WordAddin = lazyLoad(() =>
	import("./components/wordaddin/wordaddin").then((module) => ({
		default: module.WordAddin,
	}))
);

const ExcelAddin = lazyLoad(() =>
	import("./components/exceladdin/exceladdin").then((module) => ({
		default: module.ExcelAddin,
	}))
);



const OfficeSignInDialog = lazyLoad(() =>
	import("./components/signin/office-sign-in").then((module) => ({
		default: module.OfficeSignIn,
	}))
);


const Coveragemain = lazyLoad(() =>
	import("./containers/caxIBD").then((module) => ({
		default: module.caxIBD,
	}))
);

const DashboardMonday = lazyLoad(() =>
	import("./containers/dashboardMonday").then((module) => ({
		default: module.DashboardMonday,
	}))
);

/*
const TemplatesHome = lazyLoad(() =>
	import("./containers/SlideCollectionsHome").then((module) => ({
		default: module.SlideCollectionViewer,
	}))
);
*/

/*const Termsheet = lazyLoad(() =>
	import("./components/projectsax/paxTermsheet").then((module) => ({
		default: module.PaxTermsheet,
	}))
);

*/
// You need to extend the RouteObject type to include the `title` property.
/* interface CustomRouteObject extends RouteObject {
	title?: string; // Make title optional
}*/

const routes: RouteObject[] = [
	{
		//title: "PowerPoint Add-in",
		path: "/pptaddin",
		element: <PptAddin />,
	},
	{
		//title: "Admin consent request landing page",
		path: "/adminconsent",
		element: <AdminConsent />,
	},

	{
		//title: "Outlook Add-in",
		path: "/outlookaddin",
		element: <OutlookAddin />,
	},
	{
		//title: "Outlook Add-in",
		path: "/wordaddin",
		element: <WordAddin />,
	},	{
		//title: "Outlook Add-in",
		path: "/exceladdin",
		element: <ExcelAddin />,
	},
	{
		path: "/",
		//element: <DrawerProviderWrapper children={""} />,
		element: <Layout children={undefined} />,
		children: [
			{ index: true, element: <Navigate to="/wax" replace /> },

			{
				//title: "Office Add-in sign in",
				path: "officesignin",
				element: <OfficeSignInDialog />,
			},

			{
				//title: "WorkPit",
				path: "wax",
				element: <Wax />,
			},
			{
				//title: "WorkPit",
				path: "admin",
				element: <Admin />,
			},
			{
				//title: "WorkPit",
				path: "waxcompliance",
				element: <WaxCompliance />,
			},
			{
				//title: "WorkPit",
				path: "templatelibrary",
				element: <TemplateLibrary />,
			},
			/*
			{
				//title: "Template Library",
				path: "template-library",
				element: <TemplatesHome />,
			},
			*/
			{
				//title: "ProjectAX",
				path: "pax",
				element: <Pax />,
				children: [
					//{ path: "", element: <Pax /> },
					{ path: ":id", element: <Pax /> },
				],
			},
			{
				//title: "Client Coverage",
				path: "dashboard/clientcoverage",
				element: <Coveragemain />,
			},
			{
				//title: "Monday Morning",
				path: "dashboard/ibmonday",
				element: <DashboardMonday />,
			},
			{
				//title: "Term Sheet",
				//path: "dashboard/termsheet",
				//element: <Termsheet />,
			},
			{
				path: "*",
				element: <Navigate to="/404" />,
			},
		],
	},
	{
		path: "404",
		element: <NotFound />,
	},
];

export default routes;
