import { LayoutProvider } from "@/contexts/LayoutContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RelativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useMemo, useState } from "react";
import { useRoutes } from "react-router-dom";
import { LoadingPage } from "./components/layout/loading-page";
import { SignInPage } from "./components/signin/sign-in-page";
import { AuthProvider } from "./contexts/auth-context";
import { useSettings } from "./contexts/settings-context";
import routes from "./routes";
import { createCustomTheme } from "./styles/theme";
import { CalendarProvider } from "./contexts/calendar-context";
import OfficeContextProvider from "./contexts/office-client-context";
import { LockScreen } from "@/components/layout/lockScreen";
import dayjs from "dayjs";
import { NoConnection } from "./components/layout/noConnectionScreen";
import { LockScreenProvider } from "./contexts/LockScreenContext";
import { ProjectsProvider } from "./contexts/projects-context";
dayjs.extend(RelativeTime);

const App: React.FC = () => {
	const content = useRoutes(routes);
	const { settings } = useSettings();
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	const theme = useMemo(
		() => createCustomTheme({ theme: settings.theme }),
		[settings]
	);

	useEffect(() => {
		const handleOnlineStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		window.addEventListener("online", handleOnlineStatusChange);
		window.addEventListener("offline", handleOnlineStatusChange);

		return () => {
			window.removeEventListener("online", handleOnlineStatusChange);
			window.removeEventListener("offline", handleOnlineStatusChange);
		};
	}, []);

	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState === "visible") {
			if ("clearAppBadge" in navigator) {
				(navigator as any)
					.clearAppBadge()
					.catch((error: string) =>
						console.error("Error clearing badge:", error)
					);
				console.log("Clearing badge");
			}
			//(navigator as any).clearAppBadge().catch((error:string) => console.error('Error clearing badge:', error));
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{isOnline ? (
				<LockScreenProvider>
					<LayoutProvider>
						<OfficeContextProvider>
							<AuthProvider
								SignInPage={SignInPage}
								LoadingPage={LoadingPage}
							>
								<ProjectsProvider>
								<CalendarProvider>

									<LockScreen />
									{content}
								</CalendarProvider>
								</ProjectsProvider>
							</AuthProvider>
						</OfficeContextProvider>
					</LayoutProvider>
				</LockScreenProvider>
			) : (
				<NoConnection />
			)}
		</ThemeProvider>
	);
};

export default App;
