import { useAuth } from "@/contexts/auth-context";
import { useEffect, useRef, useState } from "react";

export type RecentlyAccessedFile = {
    itemId: string;
    driveId: string;
    lastAccessedDateTime: Date;
    name: string;
};

export const useMsGraphRecentDocs = (): RecentlyAccessedFile[] => {
    const [recentlyAccessFiles, setRecentlyAccessFiles] = useState<RecentlyAccessedFile[]>([]); //Array of [itemId, driveId, lastAccessedDateTime, name]
    const stateRef = useRef<RecentlyAccessedFile[]>([]);
    const { getMsGraphToken } = useAuth();


    stateRef.current = recentlyAccessFiles;

    const getRecentlyAccessedFiles = async (topn:number) => {
        const token = await getMsGraphToken();
        const response = await fetch(
            `https://graph.microsoft.com/v1.0/me/drive/recent/?$top=${topn}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const data = await response.json();

        const files = data?.value?.map((file: any) => {
            return {
                itemId: file.id,
                driveId: file.parentReference.driveId,
                lastAccessedDateTime: new Date(file.fileSystemInfo.lastAccessedDateTime),
                name: file.name,
            } as RecentlyAccessedFile;
        }) as RecentlyAccessedFile[];



        const newFiles = files?.filter((file) => (!stateRef.current?.find((existingFile) => {
            //console.log("comparing", existingFile.itemId, file.itemId, existingFile.itemId == file.itemId);    
            return (existingFile.itemId == file.itemId && existingFile.lastAccessedDateTime.getTime() == file.lastAccessedDateTime.getTime());
        })));

        //const newFiles = files.filter((file) => !stateRef.current.includes(file));


        if (newFiles) {
            const updatedMRU  = newFiles.concat(stateRef.current);
            //console.log("New files added to MRU", newFiles.length, "Updated MRU", updatedMRU);

            setRecentlyAccessFiles(updatedMRU);            
        }



    };

    useEffect(() => {
        // Call getRecentlyAccessedFiles initially
        getRecentlyAccessedFiles(60);

        // Call getRecentlyAccessedFiles every minute
        const intervalId = setInterval(() => getRecentlyAccessedFiles(3), 60000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return recentlyAccessFiles;
};
